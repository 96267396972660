import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { InfoDto } from '../../_dto/InfoDto';
import { IAlertProps } from '../../_interface/IAlertProps';
import { getDefaultAlertProps } from '../../utils/utils';
import {
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
} from '../../_constant/constants';
import { MyAlert } from '../UI/MyAlert';
import { MyTextFiled } from '../UI/MyTextField';
import { IApiResult } from '../../_interface/IApiResult';
import { AlertSeverityEnum } from '../../_enum/AlertSeverityEnum';
import { apiUpdateInfo } from '../../services/main/apiUpdateInfo';
import { MyButton } from '../UI/MyButton';

interface Props {
  open: boolean;
  onClose: () => void;
  dto: InfoDto;
}

export const MainMoreInfo: FunctionComponent<Props> = ({
  open,
  onClose,
  dto,
}) => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [checkHelper, setCheckHelper] = useState(false);

  const [addInfoDto, setAddInfoDto] = useState<InfoDto>(dto);

  const handleClose = () => {
    onClose();
    setCheckHelper(false);
  };

  const updateInfo = async () => {
    const apiResult: IApiResult = await apiUpdateInfo(addInfoDto);
    if (apiResult.isSuccess) {
      handleClose();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleUpdateInfo = async () => {
    if (addInfoDto.fullname === '' || addInfoDto.age === '') {
      setCheckHelper(true);
      return;
    }
    await updateInfo();
  };

  useEffect(() => {
    if (open) {
      setAddInfoDto(dto);
    }
  }, [open]);

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        style={{ userSelect: 'none' }}
        PaperProps={{ sx: { backgroundColor: '#e7ebee', width: '100%' } }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                style={{
                  fontFamily: 'SF Compact Rounded',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: MAIN_TITLE_FONTSIZE_STRING,
                  letterSpacing: 1,
                  color: '#000000',
                }}
                component="text"
              >
                Подробная информация
              </Typography>
            </Stack>
            <Stack>
              <IconButton title="Закрыть" size="large" onClick={handleClose}>
                <CloseIcon
                  fontSize="large"
                  sx={{
                    color: MAIN_COLOR_STRING,
                  }}
                />
              </IconButton>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Stack>
            <Stack spacing={1}>
              <Stack spacing={1}>
                <MyTextFiled
                  value={addInfoDto.fullname}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      fullname: e.target.value,
                    })
                  }
                  label="Полное имя"
                  error={checkHelper}
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.age}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      age: e.target.value,
                    })
                  }
                  label="Возраст"
                  error={checkHelper}
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.card_num}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      card_num: e.target.value,
                    })
                  }
                  label="Номер карты"
                  size="small"
                />
              </Stack>
              <Stack spacing={1}>
                <MyTextFiled
                  value={addInfoDto.period_pm_pe}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      period_pm_pe: e.target.value,
                    })
                  }
                  label="СРОК по дате ПМ или ПЭ"
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.note}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      note: e.target.value,
                    })
                  }
                  label="Особые указания"
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.bpr}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      bpr: e.target.value,
                    })
                  }
                  label="БПР"
                  size="small"
                />
              </Stack>
              <Stack spacing={1}>
                <MyTextFiled
                  value={addInfoDto.dlina_bedr_kost}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      dlina_bedr_kost: e.target.value,
                    })
                  }
                  label="Длина бедренной кости"
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.wirina_polush}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      wirina_polush: e.target.value,
                    })
                  }
                  label="Ширина полушарий"
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.zadnie_roga}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      zadnie_roga: e.target.value,
                    })
                  }
                  label="Задние рога БЖ"
                  size="small"
                />
              </Stack>
              <Stack spacing={1}>
                <MyTextFiled
                  value={addInfoDto.bol_cisterna}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      bol_cisterna: e.target.value,
                    })
                  }
                  label="Большая цистерна передне-задний размер"
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.sliv_borozda}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      sliv_borozda: e.target.value,
                    })
                  }
                  label="Сильвиева борозда"
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.par_okc_borozda}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      par_okc_borozda: e.target.value,
                    })
                  }
                  label="Парието-окципитальная борозда"
                  size="small"
                />
              </Stack>
              <Stack spacing={1}>
                <MyTextFiled
                  value={addInfoDto.poyas_borozda}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      poyas_borozda: e.target.value,
                    })
                  }
                  label="Поясная борозда"
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.tolsh_kori_lob}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      tolsh_kori_lob: e.target.value,
                    })
                  }
                  label="Толщина коры в разных отделах (лобная)"
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.tolsh_kori_temen}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      tolsh_kori_temen: e.target.value,
                    })
                  }
                  label="Толщина коры в разных отделах (теменная)"
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.tolsh_kori_zatil}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      tolsh_kori_zatil: e.target.value,
                    })
                  }
                  label="Толщина коры в разных отделах (затылочные)"
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.shpornaya_borozda}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      shpornaya_borozda: e.target.value,
                    })
                  }
                  label="Шпорная борозда (разобраться с методологией измерения)"
                  size="small"
                />
              </Stack>
              <Stack spacing={1}>
                <MyTextFiled
                  value={addInfoDto.polost_pereg_dl}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      polost_pereg_dl: e.target.value,
                    })
                  }
                  label="Полость прозрачной перегородки (длина)"
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.polost_pereg_wir}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      polost_pereg_wir: e.target.value,
                    })
                  }
                  label="Полость прозрачной перегородки (ширина)"
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.mozol_telo_dl}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      mozol_telo_dl: e.target.value,
                    })
                  }
                  label="Мозолистое тело (длина)"
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.mozol_telo_wir}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      mozol_telo_wir: e.target.value,
                    })
                  }
                  label="Мозолистое тело (толщина)"
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.mozg_cran}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      mozg_cran: e.target.value,
                    })
                  }
                  label="Мозжечок передне-задний размер"
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.mozg_megp}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      mozg_megp: e.target.value,
                    })
                  }
                  label="Мозжечок межполушарный размер"
                  size="small"
                />
              </Stack>
              <Stack spacing={1}>
                <MyTextFiled
                  value={addInfoDto.cherv_mozg_pered}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      cherv_mozg_pered: e.target.value,
                    })
                  }
                  label="Червь мозжечка передне-задний размер"
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.cherv_mozg_cranio}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      cherv_mozg_cranio: e.target.value,
                    })
                  }
                  label="Червь мозжечка кранио-каудальный размер"
                  size="small"
                />
              </Stack>
              <Stack spacing={1}>
                <MyTextFiled
                  value={addInfoDto.ugol_megdu_stvol}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      ugol_megdu_stvol: e.target.value,
                    })
                  }
                  label="Угол между стволом и червем мозжечка"
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.zrit_harizma_1}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      zrit_harizma_1: e.target.value,
                    })
                  }
                  label="Зрительная хиазма (ширина)"
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.zrit_harizma_2}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      zrit_harizma_2: e.target.value,
                    })
                  }
                  label="Зрительная хиазма (передне-задний)"
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.diam_glazn}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      diam_glazn: e.target.value,
                    })
                  }
                  label="Диаметр глазного яблока"
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.diam_glazn_2}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      diam_glazn_2: e.target.value,
                    })
                  }
                  label="Диаметр хрусталика за глазным яблоком"
                  size="small"
                />
              </Stack>
              <Stack spacing={1}>
                <MyTextFiled
                  value={addInfoDto.estr_ramer}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      estr_ramer: e.target.value,
                    })
                  }
                  label="Экстраорбитальный размер"
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.intra_razmer}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      intra_razmer: e.target.value,
                    })
                  }
                  label="Интраорбитальный размер"
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.gippo_izvilina}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      gippo_izvilina: e.target.value,
                    })
                  }
                  label="Гиппокампальная извилина"
                  size="small"
                />
              </Stack>
              <Stack spacing={1}>
                <MyTextFiled
                  value={addInfoDto.talamus_1}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      talamus_1: e.target.value,
                    })
                  }
                  label="Таламус (передне-задний)"
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.talamus_2}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      talamus_2: e.target.value,
                    })
                  }
                  label="Таламус (ширина)"
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.hvost_yadro}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      hvost_yadro: e.target.value,
                    })
                  }
                  label="Хвостатое ядро (длина)"
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.hvost_yadro_2}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      hvost_yadro_2: e.target.value,
                    })
                  }
                  label="Хвостатое ядро (ширина)"
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.a3_gelud}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      a3_gelud: e.target.value,
                    })
                  }
                  label="3 желудочек"
                  size="small"
                />
              </Stack>
              <Stack spacing={1}>
                <MyTextFiled
                  value={addInfoDto.a4_gelud}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      a4_gelud: e.target.value,
                    })
                  }
                  label="4 желудочек (ширира)"
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.a4_gelud_2}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      a4_gelud_2: e.target.value,
                    })
                  }
                  label="4 желудочек (передне-задний)"
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.wiwk_telo_dlina}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      wiwk_telo_dlina: e.target.value,
                    })
                  }
                  label="Шишковидное тело длина"
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.wiwk_telo_wirina}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      wiwk_telo_wirina: e.target.value,
                    })
                  }
                  label="Шишковидное тело ширина"
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.ostrov_dolya}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      ostrov_dolya: e.target.value,
                    })
                  }
                  label="Островковая доля"
                  size="small"
                />
                <MyTextFiled
                  value={addInfoDto.plowad_chervya}
                  onChange={(e) =>
                    setAddInfoDto({
                      ...addInfoDto,
                      plowad_chervya: e.target.value,
                    })
                  }
                  label="Площадь червя мозжечка"
                  size="small"
                />
              </Stack>
              <MyButton
                text="Сохранить"
                background={MAIN_COLOR_STRING}
                onClick={handleUpdateInfo}
              />
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};
