import axios from 'axios';
import { getDefaultApiResult, setToken } from '../utils/utils';
import {
  ACCESS_TOKEN_NAME,
  API_REQUEST_TIMEOUT_MILLISECONDS,
  JSON_CONTENT_TYPE,
  REFRESH_TOKEN_NAME,
} from '../_constant/constants';
import { IApiResult } from '../_interface/IApiResult';
import { API_URL } from '../_setting/AppSettings';

export const apiRefreshToken = async () => {
  const apiResult: IApiResult = getDefaultApiResult();
  const instance = axios.create({
    baseURL: API_URL,
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      Authorization: setToken(REFRESH_TOKEN_NAME),
    },
    timeout: API_REQUEST_TIMEOUT_MILLISECONDS,
  });
  try {
    const response = await instance.request({
      method: 'GET',
      url: '/refresh-token',
    });
    apiResult.isSuccess = true;
    apiResult.status = response.status;
    apiResult.data = response.data;
    localStorage.setItem(ACCESS_TOKEN_NAME, response.data.access_token);
    localStorage.setItem(REFRESH_TOKEN_NAME, response.data.refresh_token);
  } catch (error: any) {
    apiResult.status = error.response.status;
  }
  return apiResult;
};
