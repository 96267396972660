import React from 'react';
import { MainPageLayout } from '../layout/MainPageLayout';
import { CalcDiv } from '../component/calc/CalcDiv';
import { CalcNavigationBar } from '../component/navigation-bar/CalcNavigationBar';

export const CalcPage = () => (
  <MainPageLayout
    navigationBar={<CalcNavigationBar />}
    elementCentr={<CalcDiv />}
  />
);
