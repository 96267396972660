import {
  Box,
  Card,
  createTheme,
  Divider,
  IconButton,
  Stack,
  ThemeProvider,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { MainAddInfo } from './MainAddInfo';
import {
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
  MAIN_TITLE_FONTSIZE_STRING,
} from '../../_constant/constants';
import { IApiResult } from '../../_interface/IApiResult';
import { AlertSeverityEnum } from '../../_enum/AlertSeverityEnum';
import { IAlertProps } from '../../_interface/IAlertProps';
import { getDefaultAlertProps, isMobileView } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { MainMoreInfo } from './MainMoreInfo';
import { InfoDto } from '../../_dto/InfoDto';
import { apiGetInfo } from '../../services/main/apiGetInfo';
import { MyButton } from '../UI/MyButton';
import { apiDeleteInfo } from '../../services/main/apiDeleteInfo';
import { DialogConfirmation } from './DialogConfirmation';

export const MainDiv = () => {
  const [openMoreInfo, setOpenMoreInfo] = useState(false);
  const isMobile = isMobileView();
  const [openAddInfo, setOpenAddInfo] = useState(false);
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [infoDto, setInfoDto] = useState<InfoDto>({
    fullname: '',
    age: '',
    card_num: '',
    period_pm_pe: '',
    note: '',
    bpr: '',
    dlina_bedr_kost: '',
    wirina_polush: '',
    zadnie_roga: '',
    bol_cisterna: '',
    sliv_borozda: '',
    par_okc_borozda: '',
    poyas_borozda: '',
    tolsh_kori_lob: '',
    tolsh_kori_temen: '',
    tolsh_kori_zatil: '',
    shpornaya_borozda: '',
    polost_pereg_dl: '',
    polost_pereg_wir: '',
    mozol_telo_dl: '',
    mozol_telo_wir: '',
    mozg_cran: '',
    mozg_megp: '',
    cherv_mozg_pered: '',
    cherv_mozg_cranio: '',
    ugol_megdu_stvol: '',
    zrit_harizma_1: '',
    zrit_harizma_2: '',
    diam_glazn: '',
    estr_ramer: '',
    intra_razmer: '',
    gippo_izvilina: '',
    talamus_1: '',
    talamus_2: '',
    hvost_yadro: '',
    a3_gelud: '',
    a4_gelud: '',
    wiwk_telo_dlina: '',
    wiwk_telo_wirina: '',
    hvost_yadro_2: '',
    a4_gelud_2: '',
    ostrov_dolya: '',
    plowad_chervya: '',
    diam_glazn_2: '',
  });
  const [infoDtos, setInfoDtos] = useState<InfoDto[]>([]);

  const getInfo = async () => {
    const apiResult: IApiResult = await apiGetInfo();
    if (apiResult.isSuccess) {
      setInfoDtos(apiResult.data.info);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const deleteInfo = async (confirm: boolean) => {
    if (!confirm) {
      setOpenDialogConfirmation(false);
      return;
    }
    const apiResult: IApiResult = await apiDeleteInfo(infoDto);
    if (apiResult.isSuccess) {
      await getInfo();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setOpenDialogConfirmation(false);
  };

  const confirmDelete = (dto: InfoDto) => {
    setInfoDto(dto);
    setOpenDialogConfirmation(true);
  };

  useEffect(() => {
    if (!openMoreInfo && !openAddInfo) {
      getInfo();
    }
  }, [openMoreInfo, openAddInfo]);

  const getMoreInformation = (dto: InfoDto) => {
    setInfoDto(dto);
    setOpenMoreInfo(true);
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    ruRU
  );

  const columns: GridColDef[] = [
    {
      field: 'fullname',
      flex: 1,
      minWidth: 200,
      headerName: 'Полное имя',
      editable: false,
    },
    {
      field: 'age',
      width: 200,
      hide: isMobile,
      headerName: 'Возраст',
      editable: false,
    },
    {
      field: 'card_num',
      width: 400,
      hide: isMobile,
      headerName: 'Номер карты',
      editable: false,
    },
    {
      field: 'id',
      type: 'actions',
      width: 70,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton
            title="Просмотр информации"
            size="small"
            onClick={() => getMoreInformation(row.row)}
          >
            <FindInPageIcon
              fontSize="small"
              sx={{ color: MAIN_COLOR_STRING }}
            />
          </IconButton>
          <IconButton
            disabled={row.row.workStage === 3}
            title="Удалить"
            size="small"
            onClick={() => confirmDelete(row.row)}
          >
            <DeleteForeverIcon
              fontSize="small"
              sx={{
                color: 'red',
              }}
            />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  return (
    <Stack width="100%" alignItems="center" justifyContent="center">
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Card style={{ padding: '40px', width: '90%', marginTop: '40px' }}>
        <Stack width="100%" spacing={2}>
          <Stack
            alignItems="center"
            justifyContent="space-between"
            direction="row"
            spacing={1}
          >
            <Typography
              style={{
                fontFamily: 'SF Compact Rounded',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: MAIN_TITLE_FONTSIZE_STRING,
                letterSpacing: 1,
                color: '#000000',
              }}
              component="text"
            >
              Данные
            </Typography>
            <MyButton
              text="Создать"
              width="100px"
              height="30px"
              background={MAIN_COLOR_STRING}
              onClick={() => setOpenAddInfo(true)}
            />
          </Stack>

          <Divider />
          <div>
            <Box sx={{ width: '100%' }}>
              <ThemeProvider theme={theme}>
                <DataGrid
                  sx={{
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                      py: 1,
                    },
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                      py: '5px',
                    },
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                      {
                        py: '10px',
                      },
                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                      {
                        outline: 'none !important',
                      },
                  }}
                  getRowHeight={() => 'auto'}
                  columns={columns}
                  rows={infoDtos}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...other}
                  pageSize={20}
                  rowsPerPageOptions={[20]}
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                />
              </ThemeProvider>
            </Box>
          </div>
        </Stack>
        <MainAddInfo open={openAddInfo} onClose={() => setOpenAddInfo(false)} />
        <DialogConfirmation
          open={openDialogConfirmation}
          onClose={deleteInfo}
        />
        <MainMoreInfo
          open={openMoreInfo}
          dto={infoDto}
          onClose={() => setOpenMoreInfo(false)}
        />
      </Card>
    </Stack>
  );
};
