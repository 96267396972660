export enum ResultTypeEnum {
  'Ширина полушарий',
  'Задние рога БЖ',
  'Большая цистерна передне-задний размер',
  'Сильвиева борозда',
  'Парието-окципитальная борозда',
  'Поясная борозда',
  'Толщина коры в разных отделах (лобная)',
  'Толщина коры в разных отделах (теменная)',
  'Толщина коры в разных отделах (затылочные)',
  'Шпорная борозда (разобраться с методологией измерения)',
  'Полость прозрачной перегородки (длина)',
  'Полость прозрачной перегородки (ширина)',
  'Мозолистое тело (длина)',
  'Мозолистое тело (толщина)',
  'Мозжечок передне-задний размер',
  'Мозжечок межполушарный размер',
  'Червь мозжечка передне-задний размер',
  'Червь мозжечка кранио-каудальный размер',
  'Угол между стволом и червем мозжечка',
  'Зрительная хиазма (ширина)',
  'Зрительная хиазма (передне-задний)',
  'Диаметр глазного яблока',
  'Диаметр хрусталика за глазным яблоком',
  'Экстраорбитальный размер',
  'Интраорбитальный размер',
  'Гиппокампальная извилина',
  'Таламус (передне-задний)',
  'Таламус (ширина)',
  'Хвостатое ядро (длина)',
  'Хвостатое ядро (ширина)',
  '3 желудочек',
  '4 желудочек (ширира)',
  '4 желудочек (передне-задний)',
  'Шишковидное тело длина',
  'Шишковидное тело ширина',
  'Островковая доля',
  'Площадь червя мозжечка',
}
