import React from 'react';
import {
  AppBar,
  Box,
  createTheme,
  Stack,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { MAIN_ROUTE, SIGN_IN_ROUTE } from '../../_constant/routes';
import { MyButton } from '../UI/MyButton';

export const CalcNavigationBar = () => {
  const navigate = useNavigate();

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#605C5C',
      },
    },
  });

  const logout = () => {
    navigate(SIGN_IN_ROUTE);
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <ThemeProvider theme={darkTheme}>
        <AppBar position="static">
          <Toolbar>
            <Typography
              style={{
                fontFamily: 'SF Compact Rounded',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '18px',
                color: '#FFFFFF',
                cursor: 'pointer',
              }}
              onClick={() => navigate(MAIN_ROUTE)}
              component="text"
            >
              Расчет
            </Typography>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
    </Box>
  );
};
