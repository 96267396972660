import { JSON_CONTENT_TYPE } from '../_constant/constants';
import { SignInDto } from '../_dto/SignInDto';
import { IApiParams } from '../_interface/IApiParams';
import { getApi } from './getApi';

export const apiSignIn = async (dto: SignInDto) => {
  const params: IApiParams = {
    url: '/signIn.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
    },
  };
  return getApi(params, 'POST', dto);
};
