import { ACCESS_TOKEN_NAME, JSON_CONTENT_TYPE } from '../_constant/constants';
import { CalcDto } from '../_dto/CalcDto';
import { IApiParams } from '../_interface/IApiParams';
import { getApi } from './getApi';

export const apiCalcResult = async (dto?: CalcDto) => {
  const params: IApiParams = {
    url: '/calc/calcResult.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': localStorage.getItem(ACCESS_TOKEN_NAME),
    },
  };
  return getApi(params, 'POST', dto);
};
