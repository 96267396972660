import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
} from '../../_constant/constants';
import { IApiResult } from '../../_interface/IApiResult';
import { AlertSeverityEnum } from '../../_enum/AlertSeverityEnum';
import { IAlertProps } from '../../_interface/IAlertProps';
import { getDefaultAlertProps } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { apiGetInfo } from '../../services/main/apiGetInfo';
import { MyButton } from '../UI/MyButton';
import { MyTextFiled } from '../UI/MyTextField';
import { CalcDto } from '../../_dto/CalcDto';
import { MyAutoComplete } from '../UI/MuAutoComplete';
import { InputTypeEnum } from '../../_enum/InputTypeEnum';
import { ResultTypeEnum } from '../../_enum/ResultTypeEnum';
import { apiCalcResult } from '../../services/apiCalcResult';

export const CalcDiv = () => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [calcDto, setCalcDto] = useState<CalcDto>();

  const calcResult = async () => {
    const apiResult: IApiResult = await apiCalcResult(calcDto);
    if (apiResult.isSuccess) {
      setCalcDto(apiResult.data);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleCalc = async () => {
    /*
    if (calcDto?.resultValue !== undefined) {
      setCalcDto({
        inputType: undefined,
        inputValue: undefined,
        resultType: undefined,
        resultValue: undefined,
        resultProcebtil: undefined,
      });
      return;
    }
    */

    if (
      calcDto?.inputType === undefined ||
      calcDto?.inputType === undefined ||
      calcDto.realValue === undefined ||
      calcDto.resultType === undefined
    ) {
      return;
    }
    await calcResult();
  };

  useEffect(() => {
    // getInfo();
  }, []);

  return (
    <Stack width="100%" alignItems="center" justifyContent="center">
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="90%" margin="20px" spacing={2}>
        <Stack spacing={2}>
          <MyAutoComplete
            value={
              calcDto?.inputType !== undefined && calcDto?.inputType !== -1
                ? InputTypeEnum[calcDto?.inputType]
                : ''
            }
            onChange={(e, v) =>
              setCalcDto({
                ...calcDto,
                inputType: Object.values(InputTypeEnum).indexOf(v),
              })
            }
            disabled={
              calcDto?.resultValue !== undefined &&
              calcDto?.resultValue === undefined
            }
            arrayList={Object.values(InputTypeEnum).filter(
              (value) => typeof value === 'string'
            )}
            size="small"
            label="Тип исходных данных"
          />
          <MyTextFiled
            value={calcDto?.inputValue}
            onChange={(e) =>
              setCalcDto(
                calcDto && {
                  ...calcDto,
                  inputValue: e.target.value,
                }
              )
            }
            type="number"
            disabled={
              calcDto?.resultValue !== undefined &&
              calcDto?.resultValue === undefined
            }
            label="Значение"
            size="small"
          />
          <MyAutoComplete
            value={
              calcDto?.resultType !== undefined && calcDto?.resultType !== -1
                ? ResultTypeEnum[calcDto?.resultType]
                : ''
            }
            disabled={
              calcDto?.resultValue !== undefined &&
              calcDto?.resultValue === undefined
            }
            onChange={(e, v) =>
              setCalcDto({
                ...calcDto,
                resultType: Object.values(ResultTypeEnum).indexOf(v),
              })
            }
            maxHeight="300px"
            arrayList={Object.values(ResultTypeEnum).filter(
              (value) => typeof value === 'string'
            )}
            size="small"
            label="Исследуемый параметр"
          />
          <MyTextFiled
            value={calcDto?.realValue}
            onChange={(e) =>
              setCalcDto(
                calcDto && {
                  ...calcDto,
                  realValue: e.target.value,
                }
              )
            }
            type="number"
            disabled={
              calcDto?.resultValue !== undefined &&
              calcDto?.resultValue === undefined
            }
            label="Фактическое значение"
            size="small"
          />
          <MyButton
            text={
              calcDto?.resultValue === undefined ? 'Рассчитать' : 'Рассчитать'
            }
            background={MAIN_COLOR_STRING}
            onClick={handleCalc}
          />
          <MyTextFiled
            value={calcDto?.resultValue}
            disabled
            label="Результат"
            size="small"
          />
          <MyTextFiled
            value={calcDto?.resultProcebtil}
            disabled
            label="Процентиль"
            size="small"
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
