export const MAIN_COLOR_STRING = '#605C5C';
export const MAIN_TITLE_FONTSIZE_STRING = '20px';
export const SECONDARY_COLOR_STRING = '#00695c';
export const SECONDARY_TITLE_FONTSIZE_STRING = '16px';
export const ACCESS_TOKEN_NAME = 'access';
export const REFRESH_TOKEN_NAME = 'refresh';
export const FULLNAME_NAME = 'name';
export const ROOT_NAME = 'root';
export const ROOT_WORKER_VALUE = '1';
export const ROOT_ADMIN_VALUE = '4';
export const JSON_CONTENT_TYPE = 'application/json';
export const FORM_DATA_CONTENT_TYPE = 'form-data';
export const API_REQUEST_TIMEOUT_MILLISECONDS = 300_000;
export const API_REQUEST_TIMEOUT_MILLISECONDS_ERROR_STATUS = -10;
export const API_STATUS_PROBLEM =
  'Произошла ошибка, это все что мы знаем... Попробуйте повторить запрос позже';
