import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { MainPage } from '../../page/MainPage';
import { CalcPage } from '../../page/CalcPage';
import { SignInPage } from '../../page/SignInPage';
import { CALC_ROUTE, MAIN_ROUTE, SIGN_IN_ROUTE } from '../../_constant/routes';

export const App = () => (
  <div>
    <Routes>
      <Route path={SIGN_IN_ROUTE} element={<SignInPage />} />
      <Route path={MAIN_ROUTE} element={<MainPage />} />
      <Route path={CALC_ROUTE} element={<CalcPage />} />
    </Routes>
  </div>
);
