import { AlertSeverityEnum } from '../_enum/AlertSeverityEnum';
import { IAlertProps } from '../_interface/IAlertProps';
import { IApiResult } from '../_interface/IApiResult';

export const getDefaultAlertProps = () => {
  const alertApp: IAlertProps = {
    message: '',
    severity: AlertSeverityEnum.error,
  };
  return alertApp;
};

export const isMobileView = () => {
  const result = window.innerWidth < 820;
  return result;
};

export const getDefaultApiResult = () => {
  const apiResult: IApiResult = {
    isSuccess: false,
    status: -1,
    data: null,
    alert: getDefaultAlertProps(),
  };
  return apiResult;
};

export const changeFormatDateWithDiff = (inputValue?: string) => {
  let result = '';
  if (inputValue) {
    const arrayDate = inputValue.split('.');
    if (arrayDate.length === 3) {
      result = `${arrayDate[2]}-${arrayDate[1]}-${arrayDate[0]}`;
    } else {
      result = inputValue;
    }
  }
  return result;
};

export const changeFormatDateWithPoint = (inputValue?: string) => {
  let result = '';
  if (inputValue) {
    const arrayDate = inputValue.split('-');
    if (arrayDate.length === 3) {
      result = `${arrayDate[0]}.${arrayDate[1]}.${arrayDate[2]}`;
    } else {
      result = inputValue;
    }
  }
  return result;
};

export const setToken = (tokenType: string) =>
  `${localStorage.getItem(tokenType)}`;

export const getStatus = (value?: string) => {
  if (value === '1') {
    return 'Годен';
  }
  return 'Не годен';
};

export const getFlowmeterType = (value?: string) => {
  switch (value) {
    case '0':
      return 'G1.6';
    case '1':
      return 'G2.5';
    case '2':
      return 'G4';
    case '3':
      return 'G5';
    case '4':
      return 'G6';
    case '5':
      return 'G10';
    case '6':
      return 'G16';
    case '7':
      return 'G25';
    case '8':
      return 'G40';
    case '9':
      return 'G65';
    case '10':
      return 'G100';
    default:
      return '';
  }
};
